import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import { IS_ADMIN_STORAGE_NAME, TOKEN_STORAGE_NAME } from "../constants";

interface IUserLogin {
  exp_date?: string;
  session_id: string;
  user_id: number;
  utime: string;
  wtime: string;
  isAdmin: boolean;
}

interface IUserInfo {
  fio: string;
  email: string;
  phone: string;
  is_blocked: boolean;
}

export type LoginData = {
  email: string;
  password: string;
};

export type RegistrationData = {
  fio: string;
  email: string;
  phone: string;
  password: string;
};

export default class UserStore {
  public user: IUserLogin = {} as IUserLogin;

  public userInfo: IUserInfo | null = null;

  public isAuthorized: boolean = false;

  public isLoading: boolean = false;

  public isAdmin: boolean = false;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setAuthorized = (value: boolean) => {
    this.isAuthorized = value;
  };

  public setUser = (user: IUserLogin) => {
    this.user = user;
  };

  public setUserInfo = (userInfo: IUserInfo) => {
    this.userInfo = userInfo;
  };

  public setIsAdmin = (value: boolean) => {
    localStorage.setItem(IS_ADMIN_STORAGE_NAME, String(value));

    this.isAdmin = value;
  };

  public fetchUser = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IUserInfo>("getUser")
      .then((user) => {
        this.setUserInfo(user);
      })
      .catch(() => this.setLoading(false));
  };

  public login = async (data: LoginData): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IUserLogin>("login", undefined, data)
      .then((user) => {
        localStorage.setItem(
          TOKEN_STORAGE_NAME,
          JSON.stringify({
            session_id: user.session_id,
            user_id: user.user_id,
          })
        );

        this.setIsAdmin(user.isAdmin);
        this.setUser(user);
        this.setAuthorized(true);
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public registration = async (data: RegistrationData): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IUserLogin>("registration", undefined, data)
      .then(() => {
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public logout = async (): Promise<void> => {
    this.rootStore.setLoading(true);
    return this.rootStore
      .createRequest("logout")
      .then(() => {
        this.setAuthorized(false);
        localStorage.removeItem(TOKEN_STORAGE_NAME);
        localStorage.removeItem(IS_ADMIN_STORAGE_NAME);
        window.location.reload();
      })
      .finally(() => this.rootStore.setLoading(false));
  };

  public demoLogin = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IUserLogin>("demoLogin")
      .then((user) => {
        localStorage.setItem(
          TOKEN_STORAGE_NAME,
          JSON.stringify({
            session_id: user.session_id,
            user_id: user.user_id,
          })
        );

        this.setIsAdmin(user.isAdmin);
        this.setUser(user);
        this.setAuthorized(true);
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };
}
