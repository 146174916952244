import { FC, memo } from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";

interface ITableHeader {
  cells: Array<{
    name: string;
    width: string;
  }>;
}

const TableHeader: FC<ITableHeader> = ({ cells }) => (
  <TableHead>
    <TableRow>
      {cells.map((cell) => (
        <TableCell key={cell.name} align="left" width={cell.width}>
          {cell.name === "edit" || cell.name === "delete" ? "" : cell.name}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default memo(TableHeader);
