import { FC, memo, useEffect, useRef } from "react";
import { autorun } from "mobx";
import { useSnackbar } from "notistack";
import useStore from "./hooks/use-store";

const Notifier: FC = () => {
  const { notificationStore } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const usedIds = useRef<Array<string>>([]);
  const { notifications } = notificationStore;

  useEffect(() => {
    autorun(() => {
      notifications.forEach(({ code, type, text, options, id, params }) => {
        if (!usedIds.current.includes(id)) {
          enqueueSnackbar(text, { variant: type, ...options });

          usedIds.current.push(id);
        }
      });
    });
  }, [notifications, enqueueSnackbar]);

  return null;
};

export default memo(Notifier);
