import { FC } from "react";
import Box from "@mui/material/Box";

import {
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import SellerTokens from "../seller-tokens";
import Breadcrumbs from "../breadcrumbs";
import { useHistory, useParams } from "react-router-dom";

const Settings: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();

  const onClick = (id: string) => {
    push(`/settings/${id}`);
  };

  return (
    <Box>
      <Breadcrumbs pageName="Настройки" />
      <Stack direction="row" gap={4}>
        <MenuList>
          <MenuItem
            onClick={() => onClick("sellerTokens")}
            selected={!id || id === "sellerTokens"}
          >
            <ListItemText>Токены</ListItemText>
          </MenuItem>
          {/* <MenuItem
            onClick={() => onClick("payment")}
            selected={id === "payment"}
            disabled
          >
            <ListItemText>Оплата</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => onClick("products")}
            selected={id === "products"}
            disabled
          >
            <ListItemText>Товары</ListItemText>
          </MenuItem> */}
        </MenuList>
        <Divider orientation="vertical" sx={{ height: "unset" }} />

        {(!id || id === "sellerTokens") && <SellerTokens />}

        {/* {id === "payment" && <div>Оплата</div>}
        {id === "products" && <div>Товары</div>} */}
      </Stack>
    </Box>
  );
};

export default Settings;
