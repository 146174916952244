import React, { FC, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import { useStore } from "./hooks";
import Loader from "./components/loader/loader";
import AppBar from "./components/app-bar";

export interface IRouteProps {
  children: React.ReactNode;
  [option: string]: any;
}

const RouteView: FC<IRouteProps> = ({ children, ...rest }) => {
  const { userStore, isLoading } = useStore();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!userStore.userInfo && userStore.isAuthorized) {
      userStore.fetchUser();
    }
  }, [userStore.userInfo]);

  if (!userStore.isAuthorized) {
    return <Redirect to="/login" />;
  }

  if (userStore.isAdmin) {
    return <Redirect to="/admin" />;
  }

  return (
    <Route {...rest}>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            mt: 8,
            p: 3,
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Box>
    </Route>
  );
};

export default observer(RouteView);
