import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Backdrop,
  Box,
  Button,
  Stack,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { IUser } from "../../store/admin-store";
import styles from "./styles";
import locale from "./locale";
import TableHeader from "./components/table-header";
import AddFieldForm from "./components/add-field-form";
import { useStore } from "../../hooks";
import TableBody from "./components/table-body";
import Loader from "../loader/loader";
import { ISellerToken } from "../../store/seller-tokens-store";

interface ISellerTokensProps {
  user?: IUser | null;
}

export interface ISellerTokensForm {
  items: Array<ISellerToken>;
}

const SellerTokens: FC<ISellerTokensProps> = () => {
  const {
    userStore: { userInfo },
    sellerTokensStore: {
      fetchSellerTokens,
      addSellerToken,
      editSellerToken,
      deleteSellerToken,
      sellerTokens,
      isLoading,
    },
  } = useStore();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editData, setEditData] = useState<ISellerToken | undefined>();

  const headerCells = useMemo(() => locale.headerCells, []);

  const onAdd = useCallback(
    (data: ISellerToken) => {
      const filteredData = Object.entries(data)
        .filter(([key, value]) => !!value)
        .reduce((acc, [key, value]) => {
          //@ts-ignore
          acc[key] = value;
          return acc;
        }, {}) as ISellerToken;

      if (Boolean(editData)) {
        editSellerToken(filteredData);
      } else {
        addSellerToken(filteredData);
      }

      setShowAddForm(false);
    },
    [editData]
  );

  const toggleShowAdd = useCallback(() => {
    if (!showAddForm) {
      setEditData(undefined);
      setShowAddForm(true);
    }
  }, [showAddForm]);

  const onCancelAdd = useCallback(() => {
    setShowAddForm(false);
  }, []);

  const onRemoveField = useCallback((id: number) => {
    deleteSellerToken(id);
  }, []);

  const onEditField = useCallback(
    (id: number) => {
      const editToken = sellerTokens?.find((token) => token.id === id);
      setEditData(editToken);
      setShowAddForm(true);
    },
    [sellerTokens]
  );

  useEffect(() => {
    if (!sellerTokens) {
      fetchSellerTokens();
    }
  }, [sellerTokens]);

  return (
    <Box sx={styles.wrapper}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">{userInfo?.fio}</Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleShowAdd}
            sx={{ mr: 2 }}
          >
            Добавить
          </Button>
        </Box>
      </Stack>

      {!sellerTokens?.length && !isLoading && (
        <Typography variant="caption" mt={4}>
          У вас пока нет токенов. Нажмите кнопку добавить и заполните поля для
          создания вашего первого токена
        </Typography>
      )}
      {Boolean(sellerTokens?.length) && (
        <form>
          <TableContainer sx={{ height: 300 }}>
            <Table stickyHeader sx={styles.table}>
              <TableHeader cells={headerCells} />
              {sellerTokens && (
                <TableBody
                  fields={sellerTokens}
                  onRemove={onRemoveField}
                  onEdit={onEditField}
                />
              )}
            </Table>
          </TableContainer>
        </form>
      )}

      <Loader isLoading={isLoading} />

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={showAddForm}
      >
        <Box sx={styles.addForm} id="add-form">
          {showAddForm && (
            <AddFieldForm
              onAdd={onAdd}
              onCancel={onCancelAdd}
              data={editData}
            />
          )}
        </Box>
      </Backdrop>
    </Box>
  );
};

export default observer(SellerTokens);
