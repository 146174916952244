import { ReactElement } from "react";

import {
  AbcIcon,
  AnalyticsIcon,
  AttentionIcon,
  ComparisonIcon,
  CompetitorsIcon,
  DdcIcon,
  EconomIcon,
  ExpectedMarginIcon,
  GrafIcon,
  HeatmapIcon,
  OtherIcon,
  PnlIcon,
  RisksIcon,
  RnpIcon,
  SspIcon,
  SuppliesIcon,
  UnitEconomIcon,
  VoronkaIcon,
  WarehouseIcon,
} from "../icons";

export const NavigationItemsIcons: Record<string, ReactElement> = {
  abc: <AbcIcon />,
  analytics: <AnalyticsIcon />,
  attention: <AttentionIcon />,
  comparison: <ComparisonIcon />,
  competitors: <CompetitorsIcon />,
  ddc: <DdcIcon />,
  econom: <EconomIcon />,
  expected_margin: <ExpectedMarginIcon />,
  graf: <GrafIcon />,
  heatmap: <HeatmapIcon />,
  other: <OtherIcon />,
  pnl: <PnlIcon />,
  risks: <RisksIcon />,
  rnp: <RnpIcon />,
  ssp: <SspIcon />,
  supplies: <SuppliesIcon />,
  unit_econom: <UnitEconomIcon />,
  voronka: <VoronkaIcon />,
  warehouse: <WarehouseIcon />,
};
