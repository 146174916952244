import { FC, useCallback, useState } from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Logout,
  Settings as SettingsIcon,
  AccountCircle,
} from "@mui/icons-material";
import { useStore } from "../../hooks";
import styles from "./styles";
import { useHistory } from "react-router-dom";

const Profile: FC = () => {
  const { userStore } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { push } = useHistory();

  console.log(userStore, "userStore");
  const onOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onOpenSettings = useCallback(() => {
    push("/settings");
  }, [push]);

  return (
    <Box>
      <Tooltip title="Профиль">
        <IconButton
          onClick={onOpenMenu}
          size="medium"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircle />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        onClick={onClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: styles.menu,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box px={2} py={1}>
          <Typography variant="body2" mb={1}>
            {userStore.userInfo?.fio}
          </Typography>
          <Typography variant="caption">{userStore.userInfo?.email}</Typography>
        </Box>

        <Divider />
        <MenuItem onClick={onOpenSettings}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Настройки
        </MenuItem>
        <MenuItem onClick={userStore.logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Выйти
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default observer(Profile);
