import { FC, useEffect } from "react";
import yup from "../yup-extended";
import { SubmitHandler, useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

import Loader from "../components/loader/loader";
import useStore from "../hooks/use-store";
import { LoginData } from "../store/user-store";
import Support from "../components/support";

interface LoginProps {
  isDemo?: boolean;
}

const Login: FC<LoginProps> = ({ isDemo }) => {
  const {
    userStore: { login, demoLogin, isLoading },
  } = useStore();
  const { push } = useHistory();

  const schema = yup
    .object({
      email: yup.string().required().email("Некорретный email"),
      password: yup.string().required().min(6, "Минимум 6 символов"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginData> = (data) => {
    login(data).then(() => {
      push("/");
    });
  };

  useEffect(() => {
    if (isDemo) {
      demoLogin().then(() => {
        push("/");
      });
    }
  }, [demoLogin, isDemo, push]);

  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/login.jpg")`,

            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Войти
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register("password")}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Войти
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Восстановить пароль
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/registration" variant="body2">
                    Зарегистрироваться
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Loader isLoading={isLoading} />
          </Box>
        </Grid>
      </Grid>
      <Support />
    </Box>
  );
};

export default observer(Login);
