import { Theme, SxProps } from "@mui/material/styles";

const styles: Record<string, SxProps<Theme>> = {
  wrapper: {
    width: "100%",
    minHeight: "500px",
    position: "relative",
  },
  table: {
    "& .MuiTableCell-root": {
      p: 1,
    },
  },
  tokenValue: {
    display: "-webkit-box",
    overflow: "hidden",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
  addForm: {
    position: "fixed",
    width: "calc(100% - 250px)",
    bottom: "24px",
  },
};

export default styles;
