import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";

interface IReports {
  user_id: string;
  type_report: string;
  type_marketplace: string;
  url_report: string;
  wtime: string;
  utime: string;
  note_m: string;
  note_r: string;
  embedtoken?: string | null;
  embedurl?: string | null;
  email?: string;
}

export default class ReportsStore {
  public reports: Array<IReports> | null = null;

  public isLoading: boolean = false;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setReports = (reports: Array<IReports>) => {
    this.reports = reports;
  };

  public getReports = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<IReports>>("getReports")
      .then((reports) => {
        this.setReports(reports);
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public getEmbedToken = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<IReports>>("getEmbedToken")
      .then(() => {})
      .catch(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Не удалось обновить отчеты!",
          type: "error",
        });
      })
      .finally(() => this.setLoading(false));
  };
}
