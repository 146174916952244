import { FC, memo } from "react";
import {
  TableBody as MUTableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import styles from "../styles";
import { ISellerToken } from "../../../store/seller-tokens-store";

interface ITableBody {
  fields: Array<ISellerToken>;
  onRemove: (id: number) => void;
  onEdit: (index: number) => void;
}

const TableBody: FC<ITableBody> = ({ fields, onRemove, onEdit }) => {
  return (
    <MUTableBody>
      {(fields || []).map((field, index) => {
        const tokenValue = field.sellerToken.substring(-5);
        const tokenEndValue = field.sellerToken.substring(
          field.sellerToken.length - 5
        );

        return (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="left">{field.marketplaceType}</TableCell>
            <TableCell align="left">
              <Stack direction="row">
                <Typography sx={styles.tokenValue} component="span">
                  {tokenValue}
                </Typography>
                <Typography component="span">{tokenEndValue}</Typography>
              </Stack>
            </TableCell>
            <TableCell component="th" scope="row">
              {field.name}
            </TableCell>
            <TableCell align="left">{field.currency}</TableCell>
            <TableCell align="left">{field.taxRate}</TableCell>
            <TableCell>{field.description || ""}</TableCell>
            {field.id && (
              <TableCell align="left">
                <IconButton size="small" onClick={() => onEdit(field.id!)}>
                  <EditIcon color="action" />
                </IconButton>
              </TableCell>
            )}
            {field.id && (
              <TableCell align="left">
                <IconButton size="small" onClick={() => onRemove(field.id!)}>
                  <DeleteIcon color="action" />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </MUTableBody>
  );
};

export default memo(TableBody);
