import { FC, memo } from "react";
import { TextField, Stack, Paper, Grid, Button } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../yup-extended";
import MuiFormSelect from "../../form-controls/mui-form-select";
import { CurrencyType, MarketplacesType } from "../../../types";
import { ISellerToken } from "../../../store/seller-tokens-store";

interface IAddFieldForm {
  onAdd: (data: AddSellerTokenFormValues) => void;
  onCancel: () => void;
  data?: ISellerToken;
}

const MP_TYPES = [
  { value: "WB", label: "WB" },
  { value: "OZON", label: "OZON" },
];

const CURRENCY_TYPES = [
  { value: "RUR", label: "RUR" },
  { value: "BYN", label: "BYN" },
  { value: "KGS", label: "KGS" },
];

type AddSellerTokenFormValues = Omit<ISellerToken, "id">;

const AddFieldForm: FC<IAddFieldForm> = ({ onAdd, onCancel, data }) => {
  const schema = yup.object({
    name: yup.string().nullable(),
    description: yup.string().nullable(),
    marketplaceType: yup
      .string()
      .oneOf<MarketplacesType>(["WB", "OZON"])
      .required(),
    sellerToken: yup.string().required(),
    currency: yup
      .string()
      .oneOf<CurrencyType>(["RUR", "BYN", "KGS"])
      .nullable(),
    taxRate: yup.string().nullable(),
  });

  const methods = useForm<AddSellerTokenFormValues>({
    resolver: yupResolver<AddSellerTokenFormValues>(schema),
    defaultValues: {
      name: data?.name || undefined,
      description: data?.description || undefined,
      marketplaceType: data?.marketplaceType || "WB",
      sellerToken: data?.sellerToken || "",
      currency: data?.currency || undefined,
      taxRate: data?.taxRate || undefined,
    },
  });

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<AddSellerTokenFormValues> = (data) => {
    onAdd(data);
    reset();
  };

  const isEdit = Boolean(data);

  return (
    <Paper sx={{ p: 2, borderRadius: "8px" }} elevation={2}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "16px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <MuiFormSelect
              label="Маркетплейс"
              options={MP_TYPES}
              error={errors?.marketplaceType}
              variant="standard"
              name="marketplaceType"
              control={control}
            />
          </Grid>
          <Grid item sm={9}>
            <TextField
              label="API ключ"
              variant="standard"
              fullWidth
              error={!!errors?.sellerToken}
              helperText={errors?.sellerToken?.message}
              {...register("sellerToken")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="№ каб."
              variant="standard"
              fullWidth
              error={!!errors?.name}
              helperText={errors?.name?.message}
              {...register("name")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormSelect
              label="Валюта"
              options={CURRENCY_TYPES}
              error={errors?.currency}
              variant="standard"
              name="currency"
              control={control}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Ставка налога,%"
              variant="standard"
              fullWidth
              error={!!errors?.taxRate}
              helperText={errors?.taxRate?.message}
              type="string"
              {...register("taxRate")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Описание"
              variant="standard"
              fullWidth
              error={!!errors?.description}
              helperText={errors?.description?.message}
              {...register("description")}
            />
          </Grid>
        </Grid>

        <Stack gap={2} direction="row" mt={2}>
          <Button onClick={onCancel} variant="text">
            Отменить
          </Button>

          <Button type="submit" variant="contained">
            {isEdit ? "Редактировать" : "Добавить"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default memo(AddFieldForm);
