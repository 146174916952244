import { useEffect, useMemo } from "react";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import { useStore, useQuery } from "../../hooks";
import { MarketplacesType } from "../../types";
import { MARKETPLACES } from "../../constants";

const BUTTON_COLORS: Record<MarketplacesType, string> = {
  WB: "#8E04A9",
  OZON: "#005bff",
};

interface StyledButtonProps extends ButtonProps {
  view: MarketplacesType;
  selected: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ view, selected }) => ({
    fontWeight: 600,
    color: selected ? "white" : BUTTON_COLORS[view],
    borderColor: BUTTON_COLORS[view],
    backgroundColor: selected ? BUTTON_COLORS[view] : undefined,
    height: "31px",
    marginRight: "16px",
    "&:hover, &:active": {
      borderColor: BUTTON_COLORS[view],
      backgroundColor: selected ? BUTTON_COLORS[view] : undefined,
    },
  })
);

const Marketplaces = () => {
  const { setMarketplace, marketplace, reportsStore } = useStore();
  const { queryPatch } = useQuery();

  const filteredMarketplaces = useMemo(() => {
    if (!reportsStore.reports) {
      return [];
    }

    return MARKETPLACES.filter((mp) => {
      const hasReport = (reportsStore.reports || []).find(
        (report) => report.type_marketplace === mp.value
      );

      return Boolean(hasReport);
    });
  }, [reportsStore.reports]);

  useEffect(() => {
    const defaultMp = filteredMarketplaces[0];

    if (defaultMp) {
      setMarketplace(defaultMp.value);
      queryPatch({ marketplace: defaultMp.value });
    }
  }, [filteredMarketplaces]);

  return (
    <Box sx={{ ml: 3 }}>
      {filteredMarketplaces.map((item) => (
        <StyledButton
          variant="outlined"
          view={item.value}
          selected={marketplace === item.value}
          key={item.value}
          onClick={() => {
            setMarketplace(item.value);
            queryPatch({ marketplace: item.value });
          }}
        >
          {item.label}
        </StyledButton>
      ))}
    </Box>
  );
};

export default Marketplaces;
