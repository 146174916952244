const Locale = {
  headerCells: [
    {
      name: "Маркетплейс",
      width: "10%",
    },
    {
      name: "Токен селлера",
      width: "30%",
    },
    {
      name: "№ каб.",
      width: "15%",
    },
    {
      name: "Валюта",
      width: "15%",
    },
    {
      name: "Ставка налога,%",
      width: "15%",
    },
    {
      name: "Описание",
      width: "15%",
    },
    {
      name: "edit",
      width: "5%",
    },
    {
      name: "delete",
      width: "5%",
    },
  ],
};

export default Locale;
